import React from "react"
import ReactWOW from "react-wow"
import Matadatasmsf from "./matadatasmsf"
import scrollTo from "gatsby-plugin-smoothscroll"

import Layout from "../../components/App/Layout"
import SEO from "../../components/App/SEOSMSF"
import Navbar from "../../components/App/Navbar"
import Banner from "../../components/Smsf/bannersmsf"
import WhatIsSMSF from "../../components/Smsf/whatIsSmsf"
import Offersmsf from "../../components/Smsf/offersmsf"
import WhenShouldI from "../../components/Smsf/whenShouldI"
import GetApproval from "../../components/Smsf/getApproval"
import QuickFaq from "../../components/Smsf/quickFaq"
import Faqguid from "../../components/Smsf/Faqguid"
import CTABanner from "../../components/Common/CTABanner"
import TermsAndCondition from "../../components/Smsf/termsAndCondition"
import Footer from "../../components/App/Footer"


const smsf = () => {
  return (
    <Layout>
      <SEO title="Great SMSF Rates Start Here" description="" />
      <Matadatasmsf />
      <Navbar />

      <div className="page-smsf">
          <Banner />
          <WhatIsSMSF />
          <Offersmsf />
          <WhenShouldI/>
          <GetApproval/>
          <QuickFaq/>
          <Faqguid />
          <CTABanner />
          <TermsAndCondition/>
          <Footer />
      </div>
    </Layout>
  )
}

export default smsf
