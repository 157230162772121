import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const SEOSMSF = props => (
  <StaticQuery
    query={graphql`
      query MyQuery2 {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `}
    render={data => {
      let title = props.title || "Great SMSF Rates Start Here"
      let description = props.description || "Great SMSF Rates Start Here"
      let ogimage =
        props.ogimage || data.site.siteMetadata.siteUrl + "/ogimage.jpg"
      let smsfdata =
      props.metadatatags ||
        "Smsf, smsf loans, best smsf loans, smsf loan rates, smsf loan calculator, smsf loans for property, smsf loans for commercial property, smsf loan providers, smsf loans cba, smsf loan requirements, smsf lending, smsf loan interest rates, self managed super fund loans, self managed super fund loan rates, self managed super fund property, self managed super fund commercial property,"
      return (
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta
            name="twitter:card"
            content="Great SMSF Rates Start Here"
          ></meta>
          <link rel="canonical" href={data.site.siteMetadata.siteUrl}></link>
          <meta property="og:type" content="website" />
          <meta property="og:url" content={data.site.siteMetadata.siteUrl} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={ogimage} />
          <meta property="twitter:image" content={ogimage} />
          <meta property="og:metadatatags" content={smsfdata} />
          <meta property="twitter:metadatatags" content={smsfdata} />
        </Helmet>
      )
    }}
  />
)

export default SEOSMSF
